module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sudanese Archive","start_url":"/","background_color":"#ffffff","theme_color":"#ff7d00","display":"minimal-ui","icon":"/home/gitlab-runner/builds/jx_1pBTLZ/0/mnemonic.org/sudanesearchive-http/static/assets/header-logo.svg.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12f1b5a66fb3cdf1a5a4c66dfee1a119"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/home/gitlab-runner/builds/jx_1pBTLZ/0/mnemonic.org/sudanesearchive-http/src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
