exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-methods-tools-js": () => import("./../../../src/pages/about/methods-tools.js" /* webpackChunkName: "component---src-pages-about-methods-tools-js" */),
  "component---src-pages-about-privacy-js": () => import("./../../../src/pages/about/privacy.js" /* webpackChunkName: "component---src-pages-about-privacy-js" */),
  "component---src-pages-data-archive-js": () => import("./../../../src/pages/data-archive.js" /* webpackChunkName: "component---src-pages-data-archive-js" */),
  "component---src-pages-datasets-coup-files-js": () => import("./../../../src/pages/datasets/coup-files.js" /* webpackChunkName: "component---src-pages-datasets-coup-files-js" */),
  "component---src-pages-datasets-coupfiles-js": () => import("./../../../src/pages/datasets/coupfiles.js" /* webpackChunkName: "component---src-pages-datasets-coupfiles-js" */),
  "component---src-pages-datasets-june-3-security-js": () => import("./../../../src/pages/datasets/june-3-security.js" /* webpackChunkName: "component---src-pages-datasets-june-3-security-js" */),
  "component---src-pages-datasets-june-security-js": () => import("./../../../src/pages/datasets/june-security.js" /* webpackChunkName: "component---src-pages-datasets-june-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigations-js": () => import("./../../../src/pages/investigations.js" /* webpackChunkName: "component---src-pages-investigations-js" */),
  "component---src-templates-incident-template-js": () => import("./../../../src/templates/incident-template.js" /* webpackChunkName: "component---src-templates-incident-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-unit-template-js": () => import("./../../../src/templates/unit-template.js" /* webpackChunkName: "component---src-templates-unit-template-js" */)
}

