import { wrapTheme } from "./theme-context";
import { wrapMdx } from "./mdx-context";
import { wrapLocale } from "./locale-context";
const siteThemeConfig = require("../../config/gatsby-theme-archive-site.js");

const wrapPageElement = (apiCallbackContext) => {
  return wrapTheme(siteThemeConfig, wrapMdx(wrapLocale(apiCallbackContext)));
};

export { wrapPageElement };
