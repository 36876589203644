import React, { useContext } from "react";
import { LocaleContext } from "context/locale-context";

import { formatInTimeZone } from "date-fns-tz";
import { arSA, enUS, uk } from "date-fns/locale";
import { isEmpty, isNil, anyPass, when, pipe, take, append, join } from "ramda";

const localeToTimeLocale = (locale) => {
  switch (locale) {
    case "ar":
      return "ar-SY";
    case "uk":
      return "uk-UA";
    default:
      return "en-US";
  }
};

const truncate = when(
  (str) => str.length > 75,
  pipe(take(75), append("…"), join(""))
);

const parseDateAndTimezone = (dateString) => {
  const date = new Date(dateString);
  const tzOffset = date.getTimezoneOffset() * 60000;
  return [date, tzOffset];
};

const addLocalTimezoneToUTC = (dateString) => {
  if (!dateString) return null;
  try {
    const [date, tzOffset] = parseDateAndTimezone(dateString);
    return new Date(+date + tzOffset);
  } catch (error) {
    return null;
  }
};

function localizedDate(unixStampedDate, format) {
  const locale = useContext(LocaleContext);
  const date = addLocalTimezoneToUTC(unixStampedDate);
  const options = {
    ...(format.includes("d") && { day: "numeric" }),
    ...(format.includes("m") && { month: "long" }),
    ...(format.includes("M") && { month: "2-digit" }),
    ...(format.includes("y") && { year: "numeric" }),
    ...(format.includes("w") && { weekday: "long" }),
  };

  return date?.toLocaleDateString(localeToTimeLocale(locale), options);
}

function formatDate(date, locale = "en") {
  let localeLib;
  switch (locale) {
    case "ar":
      localeLib = arSA;
      break;
    case "uk":
      localeLib = uk;
      break;
    default:
      localeLib = enUS;
      break;
  }

  return formatInTimeZone(new Date(date), "UTC", "eee MMM dd yyyy", {
    locale: localeLib,
  });
}

const breakpoints = [576, 768, 992, 1280, 1400];
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const isNull = anyPass([isEmpty, isNil]);

export { breakpoints, localizedDate, mq, formatDate, isNull, truncate };
