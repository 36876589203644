import React, { useContext } from "react";
import styled from "@emotion/styled";
import { LocaleContext } from "context/locale-context";
import useLocalStorage from "hooks/useLocalStorage";
import useTranslations from "hooks/useTranslations";
import { rhythm } from "utils/typography";

const ToggleButton = styled.button(
  ({ isRTL }) => `
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: none;
  flex-direction: ${isRTL ? "row-reverse" : "row"};
  z-index: 5;
  border-radius: 3px;

  &:hover {
    opacity: .7;
  }

  .ToggleButton__icon, .ToggleButton__label {
    display: inline-flex;
    align-self: center;
    line-height: 1;
    margin: 2px;
  }

  .ToggleButton__label {
    padding-top: 3px;
  }
`
);

const OverlayContainer = styled.div`
  overflow: hidden;
  &.blurred > * {
    pointer-events: none;
    filter: grayscale(100%) blur(8px);
  }
`;

const SafetyOverlay = ({ children, isGraphic }) => {
  if (!isGraphic) return children;
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const [blurred, setBlurred] = useLocalStorage("safety-overlay");

  const toggleBlurred = () => {
    setBlurred(!blurred);
  };

  return (
    <div>
      <div
        css={{
          display: "inline-flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <ToggleButton
          onClick={toggleBlurred}
          css={{
            marginBottom: rhythm(0.4),
            paddingTop: rhythm(0.1),
            paddingBottom: rhythm(0.1),
          }}
        >
          <svg
            className="ToggleButton__icon"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ShieldIcon"
            height="16px"
          >
            <path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4z"></path>
          </svg>
          <small className="ToggleButton__label">
            {tr(blurred ? "Allow graphic content" : "Blur graphic content")}
          </small>
        </ToggleButton>
      </div>
      <OverlayContainer className={blurred && "blurred"}>
        {children}
      </OverlayContainer>
    </div>
  );
};

export default SafetyOverlay;
