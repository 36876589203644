import React from "react";

// custom typefaces
import "@fontsource/tajawal";
import "@openfonts/tajawal_arabic";
import { includes, without } from "ramda";

export const shouldUpdateScroll = (args, page) => {
  // A workaound, update the scroll on all pages excluding data-archive, blog-post, and method-page
  // see Gatsby.js #1719
  const windowWidth = window.innerWidth;
  const isDataarchivePage = includes("data-archive", window.location.pathname);
  const isMethodPage = includes("tools-methods", window.location.pathname);
  const pathLong = without("", window.location.pathname.split("/")).length; // pages which have TOC
  const isBlogPost = pathLong > 2; // return false for pages contains TOC
  const isMobile = windowWidth < 750; // only on Desktop
  return isDataarchivePage
    ? false
    : isBlogPost
    ? false
    : isMethodPage
    ? false
    : true;
};

export const onInitialClientRender = () => {
  window.___MNEMONIC_INITIAL_RENDER_COMPLETE = true;
};

export { wrapPageElement } from "./src/context";
