import React, { useContext } from "react";
import { ThemeContext } from "context/theme-context";

const EmphasizedText = ({ children }) => {
  const { colors } = useContext(ThemeContext);

  return (
    <h3
      style={{
        color: colors?.primary || "#ff5400",
        textAlign: "center",
      }}
    >
      {children}
    </h3>
  );
}

export default EmphasizedText;
