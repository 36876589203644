import React from "react";

const ThemeContext = React.createContext({});
const ThemeConsumer = ThemeContext.Consumer;

const defaultColors = {
  primary: "#ff5400",
  primary_transparent: "#ff54009c",
  light: "#ffffff",
  dark: "#000000",
  bg: "#f5f5f5",
};

const wrapTheme = ({ plugins, ...themeOptions }, children) => {
  return (
    <ThemeContext.Provider value={{ colors: defaultColors, ...themeOptions }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { wrapTheme, ThemeContext };
