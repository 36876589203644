import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "style/image-gallery.css";
import SafetyOverlay from "components/SafetyOverlay";

function Album({ images, isGraphic, config, children }) {
  let imgs = images;
  if (children) {
    try {
      imgs = children?.map((chld) => ({
        image: chld.props.src,
      }));
      console.log("imgs: ", imgs);
    } catch {}
  }
  const items = imgs.map((item) => {
    return {
      original: item.image,
      thumbnail: item.image,
      description: item.desc || "",
    };
  });
  const isRTL = config ? config.isRTL : false;
  return (
    <SafetyOverlay isGraphic={isGraphic}>
      <ImageGallery
        items={items}
        showThumbnails={false}
        showFullscreenButton={true}
        showPlayButton={false}
        lazyLoad={true}
        showBullets={true}
        isRTL={isRTL}
      />
    </SafetyOverlay>
  );
}

Album.propTypes = {
  images: PropTypes.array,
};

export default Album;
