import React from "react";
import SafetyOverlay from "components/SafetyOverlay";

function Image({ isGraphic, src, alt, title }) {
  return (
    <SafetyOverlay isGraphic={isGraphic}>
      <img src={src} alt={alt} title={title} />
    </SafetyOverlay>
  );
}

export default Image;
