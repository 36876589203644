import React from "react";
import { split, last } from "ramda";

import SafetyOverlay from 'components/SafetyOverlay';

function Video({ isGraphic, src, ...attributes }) {
  const format = last(split(".", src));
  return (
    <SafetyOverlay isGraphic={isGraphic}>
      <video controls width="100%" {...attributes}>
        <source src={src} type={`video/${format}`} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </SafetyOverlay>
  );
}

export default Video;
