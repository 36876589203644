import React from "react";

const defaultLocale = "en";
const LocaleContext = React.createContext(defaultLocale);
const LocaleConsumer = LocaleContext.Consumer;

const defaultLocation = {};
const LocationContext = React.createContext(defaultLocation);
const LocationConsumer = LocationContext.Consumer;

const wrapLocale = ({ element, props }, b) => {
  const { pathname } = props.location;

  const locale = pathname === "/"
    ? "en"
    : pathname.split("/")[1]

  return (
    <LocationContext.Provider value={props.location}>
      <LocaleContext.Provider value={locale}>
        {element}
      </LocaleContext.Provider>
    </LocationContext.Provider>
  );
};

export {
  wrapLocale,
  LocaleConsumer,
  LocaleContext,
  LocationContext,
  LocationConsumer,
};
