import React from "react"
import { MDXProvider } from "@mdx-js/react"

import Album from 'components/Album'
import TitleWithIcon from 'components/mdx-components/title-with-icon'
import EmphasizedText from 'components/mdx-components/EmphasizedText'
import Video from 'components/mdx-components/video'
import Image from 'components/mdx-components/image'
import WorkflowDiagram from 'components/WorkflowDiagram'

const components = { Album, Image, EmphasizedText, TitleWithIcon, Video, WorkflowDiagram }

const wrapMdx = (children) => (
  <MDXProvider components={components}>{children}</MDXProvider>
)

export { wrapMdx };
