const translations = require("./translations/translations.json");

module.exports = {
  cmsUrl: "https://cms.sudanesearchive.org",
  apiUrl: "https://api.mnemonic.org",
  contentPath: `${__dirname}/content/`,
  dataPath: `${__dirname}/data/`,
  siteIconPath: `${__dirname}/static/assets/header-logo.svg.png`,
  actionButtonLink: "about/contact",
  analyticsTrackingId: "G-PPTSHY93G8",
  colors: {
    primary: "#1283bf",
    primary_transparent: "#1283bf9c",
    light: "#ffffff",
    dark: "#000000",
    bg: "#f5f5f5",
  },
  footerLinks: [
    { title: "about", to: "about" },
    { title: "contact", to: "about/contact" },
    { title: "press", to: "about/press" },
    { title: "privacy", to: "about/privacy" },
  ],
  mailchimpEndpoint:
    "https://sudanesearchive.us3.list-manage.com/subscribe/post?u=58fde0af414890c4c9c855f78&id=6d260feea1",
  socialMediaLinks: [
    { type: "facebook", link: "https://facebook.com/sudanesearchive" },
    { type: "twitter", link: "https://twitter.com/sudan_archive" },
    { type: "instagram", link: "https://instagram.com/sudanese_archive/" },
  ],
  homepageSupportButtonLink: "about/team",
  locales: ["en", "ar"],
  siteName: "Sudanese Archive",
  siteNameAr: "الأرشيف السوداني",
  translations,
  workflowDiagramCounts: {
    collect: "2,264",
    preserve: "313,896",
    process: "",
    verify: "",
    investigate: "",
  },
};
