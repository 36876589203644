import React from "react";

function TitleWithIcon({ title, icon }) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
        marginTop: "3rem"
      }}
    >
      <img src={icon} css={{ margin: 0 }} />
      <h2 css={{ margin: "1rem", marginBottom: 0, marginTop: "0.5rem" }}>
        {title}
      </h2>
    </div>
  );
}

export default TitleWithIcon;
