import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import useTranslations from "hooks/useTranslations";

import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import { mq } from "utils/helper";

const workflowItems = [
  {
    label: "collect",
    desc: "Sources",
    id: "0",
    image: "/assets/methodology-collect.svg",
  },
  {
    label: "preserve",
    desc: "Videos",
    id: "1",
    image: "/assets/methodology-preserve.svg",
  },
  {
    label: "process",
    desc: "Videos",
    id: "2",
    image: "/assets/methodology-process.svg",
  },
  {
    label: "verify",
    desc: "Videos",
    id: "3",
    image: "/assets/methodology-verify.svg",
  },
  {
    label: "investigate",
    desc: "Incidents",
    id: "4",
    image: "/assets/methodology-investigate.svg",
  },
];

const Step = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: props.isRTL ? rhythm(1.4) : rhythm(0.8),
  marginRight: props.isRTL ? rhythm(1.4) : rhythm(0.8),
  [mq[1]]: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: rhythm(1),
  },
}));

const Label = styled.p((props) => ({
  ...scale(0.45),
  color: props.colors.primary,
  letterSpacing: props.isRTL ? "normal" : "1.5px",
  marginBottom: rhythm(0),
  textTransform: "uppercase",
  [mq[1]]: {
    marginTop: rhythm(0.5),
  },
}));

const Desc = styled.small({
  margin: 0,
  fontSize: "85%",
  fontWeight: 500,
  opacity: 0.8,
});

function Item({ count, item, isRTL }) {
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <Step isRTL={isRTL}>
        <img
          src={item.image}
          css={{
            display: "none",
            marginRight: isRTL ? 0 : rhythm(2),
            marginLeft: isRTL ? rhythm(2) : 0,
            [mq[1]]: {
              display: "block",
            },
          }}
        />
        <div
          css={{
            marginRight: isRTL ? 0 : "auto",
            marginLeft: isRTL ? "auto" : 0,
          }}
        >
          <Label isRTL={isRTL} colors={colors}>
            {tr(item.label)}
          </Label>
          <Desc>
            {count} {tr(item.desc)}
          </Desc>
        </div>
      </Step>
      {isRTL ? (
        <MdKeyboardArrowLeft
          css={{
            fontSize: "24px",
            opacity: 0.6,
            ":last-of-type": {
              display: "none",
            },
            [mq[1]]: {
              display: "none",
            },
          }}
        />
      ) : (
        <MdKeyboardArrowRight
          css={{
            fontSize: "24px",
            opacity: 0.6,
            ":last-of-type": {
              display: "none",
            },
            [mq[1]]: {
              display: "none",
            },
          }}
        />
      )}
    </>
  );
}

function Workflow({ labelStyle }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors, workflowDiagramCounts } = useContext(ThemeContext);
  const isRTL = locale === "ar";

  return (
    <>
      <div
        css={{
          backgroundImage: "url(/assets/homepage-graph.svg)",
          backgroundRepeat: "no-repeat",
          height: "250px",
          width: "100%",
          transform: isRTL ? "scaleX(-1)" : "none",
          filter: isRTL ? "FlipH" : "none",
          [mq[1]]: {
            display: "none",
          },
        }}
      ></div>
      <div
        css={{
          width: "100%",
          marginBottom: rhythm(2),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [mq[1]]: {
            display: "block",
          },
          ...labelStyle,
        }}
      >
        {workflowItems.map((item) => (
          <Item
            key={item.id}
            item={item}
            isRTL={isRTL}
            count={workflowDiagramCounts[item.label]}
          />
        ))}
      </div>
    </>
  );
}

Item.propTypes = {
  item: PropTypes.object,
  isRTL: PropTypes.bool,
};

export default Workflow;
