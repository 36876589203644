import React, { useContext } from "react";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import themeTranslations from "../../config/translations/translations.json";

export default function useTranslations() {
  const locale = useContext(LocaleContext);
  const { locales, translations: siteSpecificTranslations } =
    useContext(ThemeContext);

  const translations = {};
  locales?.forEach(
    (locale) =>
      (translations[locale] = {
        ...themeTranslations[locale],
        ...siteSpecificTranslations[locale],
      })
  );

  return function tr(s, forceLocale) {
    try {
      return translations[forceLocale || locale][s.toLowerCase()]
        ? translations[forceLocale || locale][s.toLowerCase()]
        : s;
    } catch (e) {
      return s;
    }
  };
}
